import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import SimpleSnackbar from "../components/SimpleSnackbar";
import PopupRecipesToPrint from "../components/recipes/toolbar/PopupRecipesToPrint";
import Protected from "../routes/Protected";
import { GET, POST } from "../helpers/requests";
import { pathFolder } from "../utils/index";
import { Url } from "../Config/url";
import FolderAPI from "../backend/folder";
import { getLanguage, canRead, canWrite, getRole, getUser } from "../helpers/auth";
import I18n from "../Config/I18n";
import PopUpFolderDelete from "../components/folders/PopUpDeleteFolder";
import FolderContent from "../components/folders/FolderContent";
import { PageToolbarContainer } from "../components/drawer";
import RecipesPageToolbar from "../components/folders/toolbars/recipesPageToolbar";
import { useHistory } from "react-router-dom";
import RecipesToolbar from "../components/folders/toolbars/recipesToolbar";
import RecipeCard2 from "src/components/RecipeCard2";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/loader";
import SearchControler from "src/utils/filter";

const l = getLanguage();

// TODO this page component has been renamed to recipes because thats the correct name. Should handle navbar

export default function RecipesPage() {
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false); // snackbar
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [alertVariant, setAlertVariant] = useState("dark");
  const [allRecipes, setAllRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [newFolder, setNewFolder] = useState("");
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);
  const [folders, setFolders] = useState({
    _id: "",
    name: "",
    childrens: [],
    recipes: [],
  });
  const [searchByName, setSearchByName] = useState("");
  const [searchByAuthor, setSearchByAuthor] = useState("");
  const [searchByCategory, setSearchByCategory] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("");
  const [folderToDelete, setFolderToDelete] = useState({
    _id: "",
    name: "",
  });

  const history = useHistory();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    const path = pathFolder(); // folders/sub_folders/sub_sub_folders...
    await Promise.all([
      loadAllRecipesInFolderRoot(),
      //loadFolder(path),
      loadCategoryRecipes(),
      loadStatus(),
    ]);
    setLoading(false);
  };

  // Get all recipes in folder
  const loadAllRecipesInFolderRoot = async () => {
    // const allRecipes = await GET('/folders/all');
    const allRecipes = await GET("/folders/all");
    if (allRecipes.error) {
      return console.error(allRecipes.error.message);
    }
    const recipesSorted = allRecipes.data;
    setAllRecipes(recipesSorted);
  };

  const loadCategoryRecipes = async () => {
    const categories = await GET("/recipe/category");
    if (categories.error) {
      return console.error(categories.error.message);
    }
    const userId = getUser()._id;

    categories.data.forEach(cat => {
      if (cat.ownerId === userId) {
        cat.name += "*";
      } else {
        cat.name += "°";
      }
    });

    setCategories(categories.data);
  };

  const loadStatus = async () => {
    const resp = await GET("/recipe/status");
    if (resp.error) {
      return console.error(resp.error.message);
    }
    setStatus(resp.data);
  };

  const addFolder = async folder => {
    const data = {
      parentId: folders._id,
      name: folder,
    };
    setLoading(true);
    const result = await FolderAPI.add(data);
    if (result.error) {
      if (result.error.alreadyExist) sendSnack("Folder already exists", "danger");
    } else {
      sendSnack("ok :)", "success");
      const newFolder = result.data;
      const folder = { ...folders };
      folder.childrens.push(newFolder);
      setFolders(folder);
    }
    setLoading(false);
    loadData();
  };

  const deleteFolder = folderId => {
    const data = {
      parentId: folders._id,
      folderId: folderToDelete._id || folderId,
    };

    POST("/folders/delete", data).then(response => {
      if (response.error) {
        return console.error(response.error.message);
      } else {
        const childrens = [...folders.childrens];
        const newChilder = childrens.filter(folder => folder._id !== folderId);
        setFolders({ ...folders, childrens: newChilder });
        setFolderToDelete({ _id: "", name: "" });
        loadData();
      }
    });
  };

  // open snackbar
  const handleOpen = () => {
    setOpenSnackbar(true);
  };

  // close snackbar
  const handleClose = (event, reason) => {
    if (reason !== "clickaway") return setOpenSnackbar(false);
  };

  const sendSnack = (message, type, time = 2000) => {
    setMessageSnackBar(message);
    setAlertVariant(type);
    handleOpen();
    setTimeout(handleClose, time);
  };

  const handleClickOpenPopupPrint = () => {
    setOpen(true);
  };

  const handleClosePopupPrint = () => {
    setOpen(false);
  };

  if (window.location.pathname.includes("//")) window.location.pathname = window.location.pathname.replace("//", "/");

  return (
    <Protected
      role={getRole()}
      perform="payment:must-pay"
      data={{
        canWrite: canWrite(),
        canRead: canRead(),
      }}
      yes={() => (
        <>
          <PageToolbarContainer>
            <RecipesPageToolbar
              newFolder={newFolder}
              setNewFolder={setNewFolder}
              addFolder={addFolder}
              button={I18n.t("folder.addFolder", { locale: l })}
              redirectTo={Url.recipe}
              search={search}
              setSearch={setSearch}
              handleClickOpenPopupPrint={handleClickOpenPopupPrint}
              searchByName={searchByName}
              setSearchByName={setSearchByName}
              searchByAuthor={searchByAuthor}
              setSearchByAuthor={setSearchByAuthor}
              searchByCategory={searchByCategory}
              setSearchByCategory={setSearchByCategory}
              searchByStatus={searchByStatus}
              setSearchByStatus={setSearchByStatus}
              categories={categories}
              status={status}
            />
            {open && (
              <PopupRecipesToPrint
                handleClickOpenPopupPrint={handleClickOpenPopupPrint}
                handleClosePopupPrint={handleClosePopupPrint}
                open={open}
                allRecipes={allRecipes}
                status={status}
                categories={categories}
              />
            )}
          </PageToolbarContainer>
          <div className="mt-3">
            {searchByName || searchByAuthor || searchByCategory || searchByStatus ? (
              <AllRecipesFiltered
                searchByName={searchByName}
                searchByAuthor={searchByAuthor}
                searchByCategory={searchByCategory}
                searchByStatus={searchByStatus}
                categories={categories}
                folders={folders}
                status={status}
                allRecipes={allRecipes}
                reloadRecipes={loadData}
              />
            ) : (
              <FolderContent path={pathFolder()} status={status} categories={categories} />
            )}
            <SimpleSnackbar open={openSnackbar} handleClose={handleClose} message={messageSnackBar} variant={alertVariant} />
          </div>
          <PopUpFolderDelete
            open={folderToDelete._id ? true : false}
            folderId={folderToDelete._id}
            folderName={folderToDelete.name}
            closeFunction={() => setFolderToDelete({ _id: "", name: "" })}
            deleteFunction={deleteFolder}
          />
        </>
      )}
      no={() => {
        history.push(Url.payment);
        return history.go(0);
      }}
    />
  );
}

// <Redirect to="/payment" />

const AllRecipesFiltered = ({
  searchByName,
  searchByAuthor,
  searchByCategory,
  searchByStatus,
  categories,
  folders,
  status,
  allRecipes,
  reloadRecipes,
}) => {
  const [rendred, setRendred] = useState(1);

  const filteredRecipes = () => {
    var recipes = [...allRecipes];
    if (searchByName) {
      recipes = recipes.filter(recipe => SearchControler.isSearchingFit(recipe.name, searchByName));
    }
    if (searchByAuthor) {
      recipes = recipes.filter(recipe => SearchControler.isSearchingFit(recipe.author, searchByAuthor));
    }
    if (searchByCategory) {
      recipes = recipes.filter(recipe => {
        if (!recipe.categoryId) return false;
        return recipe.categoryId.includes(searchByCategory);
      });
    }
    if (searchByStatus) {
      recipes = recipes.filter(recipe => {
        if (!recipe.statusId) return false;
        return recipe.statusId.includes(searchByStatus);
      });
    }
    return recipes;
  };
  const recipeFiltered = filteredRecipes();
  const loadSize = 20;
  let recipesToShow = recipeFiltered.slice(0, loadSize * rendred);

  return (
    <div className="">
      <RecipesToolbar />
      <div className="flex flex-wrap">
        {recipeFiltered.length >= 0 ? (
          <InfiniteScroll
            dataLength={recipesToShow.length}
            hasMore={recipesToShow.length < recipeFiltered.length}
            next={() => {
              setRendred(rendred + 1);
            }}
            loader={<Loader />}
            className="flex flex-wrap"
          >
            {recipesToShow.map((recipe, index) => {
              return (
                <Col key={recipe._id} className="col-auto mb-3">
                  <RecipeCard2 recipe={recipe} status={status} categories={categories} folders={folders} showPath update={reloadRecipes} />
                </Col>
              );
            })}
          </InfiniteScroll>
        ) : (
          <Col className="text-center">
            <br />
            <br />
            <br />
            <br />
            <br />
            <h5>No recipes to show</h5>
            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        )}
      </div>
    </div>
  );
};
