import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { getId, getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { Url } from "../../../Config/url";
import { IconLinkOrange } from "src/components/links/iconLinkOrangeOutline";
const l = getLanguage();

const RecipesToolbar: FC<{ folderData?: any }> = ({ folderData }) => {
  return (
    <div className="flex px-3 pb-3 mb-3 border-b gap-x-3">
      <h3 className="my-auto text-xl font-bold">{I18n.t("recipeObject.recipes", { locale: l })}</h3>
      {folderData?._id && (
        <OverlayTrigger overlay={<Tooltip id="button-tooltip">{I18n.t("recipeObject.addRecipe", { locale: l })}</Tooltip>}>
          <IconLinkOrange redirectUrl={`${Url.recipe}?folderId=${folderData._id}`} hidden={folderData.ownerId !== getId()}>
            <IoMdAdd />
          </IconLinkOrange>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default RecipesToolbar;
